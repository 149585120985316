import './App.scss';
import AnonPayContainer from './app/containers/AnonPayContainer/AnonPayContainer';
import {useSearchParams} from "react-router-dom";

//fonts
import "./fonts/FjallaOne-Regular.ttf"

function App() {
  const [searchParams] = useSearchParams();

  return (
    <div className="App">
      <AnonPayContainer searchParams={searchParams}/>
    </div>
  );
}

export default App;
