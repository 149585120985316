import React, {useState} from "react";

import PaymentHeader from "../../../components/PaymentHeader/PaymentHeader";
import TextInput from "../../../components/TextInput/TextInput";
import "./CustomerDetailsSubcontainer.scss";
import SelectInput from "../../../components/SelectInput/SelectInput";
import countryList from 'country-list'
import CellInput from "../../../components/CellInput/CellInput";


const CustomerDetailsSubcontainer = props => {
  const handleSubmit = (event) => {
    event.preventDefault();

    let jsonBody = Object.fromEntries(new FormData(event.target).entries())

    if (!validateForm(jsonBody)) {
      throw new Error(`Invalid invoice submission`);
    }

    mapData(jsonBody)

    fetch(event.target.action, {
      method: 'POST',
      headers: new Headers([['Content-Type', 'application/json']]),
      body: JSON.stringify(jsonBody)
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      props.state({customerDetailsSet: true})
    }).then((body) => {
    }).catch((error) => {
    });
  }

  return (
    <div className="CustomerDetailsSubcontainer">
      <div className='header'>
        <PaymentHeader header="Express Checkout" subhead="Contact information and shipping address"/>
        <div className='merchant-logo'>
          <img src={'bd.png'}/>
        </div>
      </div>
      <form onSubmit={handleSubmit} action="https://pay.colorcrypto.io/public-api/v1/customer-details-form" method="POST">
        <div className="form">
          <TextInput type="email" label="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required={true}/>
          <TextInput type="name" name="name" label="Full name" required={true}/>
          <TextInput type="address_1" label="Street and house number" required={true}/>
          <TextInput type="address_2" label="Additional address (optional)"/>
          <TextInput type="city" label="City" required={true}/>
          <TextInput type="zip" label="Postal code" required={true}/>
          <TextInput type="state" label="State/Province"/>
          <SelectInput name="country" label="Country" values={countryList.getNames().sort()} required={true}/>
          <CellInput name="phone" label="Phone" required={true}/>
          <input type="hidden" id="invoiceid" name="invoice_id" value={props.invoiceId}/>
        </div>
        <input type="submit" value="Continue"/>
      </form>
    </div>
  );
}

const validateForm = (jsonBody) => {
  let invoiceId = jsonBody.invoice_id
  return (/[a-z0-9A-Z]{15,36}$/.test(invoiceId));
}

const mapData = (jsonBody) => {
  let country = jsonBody.country
  if (country) {
    jsonBody.country = countryList.getCode(country)
  }
}

export default CustomerDetailsSubcontainer;
