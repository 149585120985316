import React from "react";

import "./PaymentDetails.scss";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import Countdown from "react-countdown-simple";
import InvoiceDetails from "../InvoiceDetails/InvoiceDetails";
import Footer from "../Footer/Footer";


const PaymentDetails = props => {
  let addressHeading = props.currency === 'USDT' ? 'Address (ERC20 Only)' : 'Address'
  if (props.invoiceState === "PAID" && props.successUrl !== null) {
    setTimeout(function () {
      window.location.href = props.successUrl
    }, 1000);
  }

  return (
    <div style={{display: "flex"}}>
      <div className="payment-details">
        <div className="heading">Payment Steps</div>
        <div
          className="values"
          style={{
            fontSize: "13px",
            textAlign: "left"
          }}>
          <strong>Step 1:</strong> Make payment from your crypto wallet or exchange account to the address below.
          <span className="emphasize"> Scroll down on widget to see more.</span>
          <br/>
          <br/>
          <strong>Step 2:</strong> After payment has been made, click on the Check payment status button below until
          the transaction appears on the blockchain with a few confirmations.
        </div>
        <div className="refresh-container">
          <div
            className="refresh-button"
            onClick={() => {
              props.refreshPaymentStatus()
            }}
          >
            Check payment status
          </div>
        </div>
        <div className="heading">Payment status</div>
        <div className={invoiceStateClasses(props.invoiceState)}>{invoiceStateValue(props.invoiceState)}</div>
        <div className="heading">{props.currency} {addressHeading}</div>
        <div className="values">
          <div>
            <div>
              {addressValue(props.address)}
            </div>
            <Countdown renderer={renderer} targetDate={props.expiryTime}/>
          </div>
        </div>
        <div className="heading">Amount</div>
        <div className="values">{amountValue(props.amount, props.currency)}  </div>

        <div className="heading">QR Code</div>
        <div className="values">
          {qrCodeValue(props.address)}
          <div id="qr_code" className="qr"></div>
        </div>

        <div className="heading">Order details</div>
        <div>
          <InvoiceDetails invoiceId={props.invoiceId} total={props.invoiceTotal} currency={props.invoiceCurrency}
                          lineItems={props.lineItems} buyerName={props.buyerName} buyerEmail={props.buyerEmail}
                          shippingAddress={props.shippingAddress} />
        </div>
        <Footer footer="Powered by colorcrypto.io" />
      </div>
    </div>
  );
}

const renderer = ({minutes, seconds}) => {
  if (minutes === 0 && seconds === 0) {
    return <div className="expiry_time">Address expired</div>
  } else if (!isNaN(seconds)) {
    return <div className="expiry_time">Address expiring in: {minutes}:{seconds}</div>
  }
}

const invoiceStateValue = (invoiceState) => {
  if (invoiceState !== undefined) {
    return invoiceState
  } else {
    return (
      <Skeleton height={24} width={150}/>
    )
  }
}

const invoiceStateClasses = (invoiceState) => {
  if (invoiceState === "PAID") {
    return "values green"
  } else if (invoiceState === "PENDING") {
    return "values"
  } else {
    return "values red"
  }
}

const addressValue = (address) => {
  if (address !== undefined) {
    return address
  } else {
    return (
      <Skeleton height={24} width={300}/>
    )
  }
}

const amountValue = (amount, currency) => {
  if (amount !== undefined) {
    return amount + " " + currency
  } else {
    return (
      <Skeleton height={24} width={180}/>
    )
  }
}

const qrCodeValue = (address) => {
  if (address !== undefined) {
    return null

  } else {
    return (
      <Skeleton height={180} width={180}/>
    )
  }
}

export default PaymentDetails;
