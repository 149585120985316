import {useState} from "react";
import "./SelectInput.scss";

function SelectInput({name, label, values, required}) {
  const [value, setValue] = useState('');

  function handleChange(e) {
    setValue(e.target.value);
  }

  let className = value === '' ? '' : 'filled'
  className += required === true ? ' required' : ''

  return (
    <div className="select-container">
      <select name={name} onChange={handleChange} defaultValue='' required={required}>
        <option disabled value=''></option>
        {
          values.map((value, key) =>
            <option key={key}>{value}</option>)
        }
      </select>
      <label className={className} htmlFor={value}>
        {label}
      </label>
    </div>
  );
}

export default SelectInput;