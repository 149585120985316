import React from "react";
import CurrencyOption from "../CurrencyOption";
import "./CurrencyOptionGrid.scss";

const currencies = (currencyCodeList, setCurrency) => {
    return currencyCodeList.map((currencyCode) => (
        <div
            className="grid-item"
            key={currencyCode + "_option"}
            onClick={() => {
                setCurrency(currencyCode)
            }}
        >
            <CurrencyOption
                currencyCode={currencyCode}
            />
        </div>
    ));
}

const CurrencyOptionGrid = ({currencyCodeList, setCurrency}) => {

    return (
        <div className="grid-container">
            {currencies(currencyCodeList, setCurrency)}
        </div>
    );
}

export default CurrencyOptionGrid;
