import React from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import "./InvoiceDetails.scss";


const InvoiceDetails = props => {
  return (
    <div className="invoice-details">
      <div>
        <h4>Payment Reference : {props.invoiceId}</h4>
      </div>
      <hr/>
      <div>
        <div className="line-items">
          <h4>Customer: </h4><h4 className="value">{props.buyerName}</h4>
        </div>
        <div className="line-items">
          <span>Email: </span><span className="value">{props.buyerEmail}</span>
        </div>
        <div className="line-items">
          <span>Shipping Address: </span><span className="value">{props.shippingAddress}</span>
        </div>
      </div>
      <hr/>
      <div>
        <h4>Line Items</h4>
        {lineItemsValue(props.lineItems, props.currency)}
      </div>
      <hr/>
      <div>
        <div className="line-items">
          <span>Total</span><span className="value">{totalValue(props.total, props.currency)}</span>
        </div>
      </div>
    </div>
  );
}

const totalValue = (total, currency) => {
  if (total === undefined) {
    return <Skeleton height={24} width={100}/>
  } else {
    return total + " " + currency
  }
}

const lineItemsValue = (lineItems, currency) => {
  if (lineItems === undefined || currency === undefined) {
    return <Skeleton height={50} width={350}/>
  } else {
    return renderLineItems(lineItems, currency)
  }
}

const renderLineItems = (lineItems, currency) => {
  if (lineItems === undefined) {
    return null
  } else {
    let renderItems = lineItems.map((item) => {
      let quantityText = item.quantity > 1 ? " ( x " + item.quantity + " )" : ""
      return (
        <div className="line-items">
          <span>{item.description}{quantityText}</span><span className="value">{item.amount} {currency}</span>
        </div>
      )
    })
    return (
      <div>
        {renderItems}
      </div>
    )
  }
}

export default InvoiceDetails;
