import React from "react";

const CurrencyOption = ({currencyCode}) => {
  return (
    <div className="CurrencyOption">
      <img
        src={"https://pay.colorcrypto.io/images/currencies/" + currencyCode + ".png"}
        alt={currencyCode}
        style={{width: "86px", height: "86px", margin: "6px"}}
      />
      <br/>
      {currencyCode}
    </div>
  );
}

export default CurrencyOption;
