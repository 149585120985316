import React, {useState} from "react";

import PaymentHeader from "../../../components/PaymentHeader/PaymentHeader"
import PaymentDetails from "../../../components/PaymentDetails/PaymentDetails";

import "./PayWithCurrencySubcontainer.scss";
import "../../../util/util"

import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import QRCode from "qrcode";
import {currencyNameMap} from "../../../util/util";

const loadState = {
  UNDEFINED: 0,
  LOADING: 1,
  LOADED: 2
}

const getCurrencyPaymentDetails = (currency, invoiceId, setValueState) => {
  setValueState({state: loadState.LOADING})
  axios.get('https://pay.colorcrypto.io/public-api/v1/get-payment-details', {
    //   axios.get('http://localhost:8000/public-api/v1/get-payment-details', {
    params: {
      'currency': currency,
      'invoice-id': invoiceId
    }
  })
    .then((response) => {
      setValueState({
        state: loadState.LOADED,
        address: response.data.address,
        amount: response.data.amount,
        invoiceState: response.data.invoice_state,
        expiryTime: response.data.expiry_time,
        blockchainHash: response.data.blockchain_hash,
        invoiceCurrency: response.data.invoice_currency,
        invoiceTotal: response.data.invoice_total,
        lineItems: response.data.line_items,
        successUrl: response.data.success_url,
        buyerName: response.data.buyer_name,
        buyerEmail: response.data.buyer_email,
        shippingAddress: response.data.shipping_address,
      })
    })
    .catch((error) => {
      console.log(error);
    })
}

const PayWithCurrencySubcontainer = props => {
  const [valuesState, setValueState] = useState({
    state: loadState.UNDEFINED,
    address: null,
    amount: null,
    invoiceState: null,
    expiryTime: null,
    blockchainHash: null,
    invoiceCurrency: null,
    invoiceTotal: null,
    lineItems: null,
    successUrl: null,
    buyerName: null,
    buyerEmail: null,
    shippingAddress: null,
  })

  if (valuesState.state === loadState.UNDEFINED) {
    removeExistingQRCode()
    getCurrencyPaymentDetails(props.activeCurrency, props.invoiceId, setValueState)
  } else if (valuesState.state === loadState.LOADED) {
    let data = currencyNameMap[props.activeCurrency] + ':' + valuesState.address + '?amount=' + valuesState.amount +
      '&label=ColorCrypto'
    if (valuesState.address !== undefined) {
      addQRCode(data)
    }
  }

  const refreshPaymentStatus = () => {
    setValueState({
      state: loadState.UNDEFINED,
      address: null,
      amount: null,
      invoiceState: null,
      expiryTime: null,
      blockchainHash: null,
      invoiceCurrency: null,
      invoiceTotal: null,
      lineItems: null,
      successUrl: null,
      buyerName: null,
      buyerEmail: null,
      shippingAddress: null,
    });
  }

  return (
    <div>
      <div className="payment-heading">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="fa-2x back-button"
          onClick={() => {
            props.goBack()
          }}
        />
        <PaymentHeader header="Crypto Payment" subhead="Select currency to pay" currencySubhead={props.activeCurrency}/>
      </div>
      <PaymentDetails
        currency={props.activeCurrency}
        address={valuesState.address}
        amount={valuesState.amount}
        invoiceState={valuesState.invoiceState}
        expiryTime={valuesState.expiryTime}
        blockchainHash={valuesState.blockchainHash}
        invoiceCurrency={valuesState.invoiceCurrency}
        invoiceTotal={valuesState.invoiceTotal}
        lineItems={valuesState.lineItems}
        successUrl={valuesState.successUrl}
        buyerName={valuesState.buyerName}
        buyerEmail={valuesState.buyerEmail}
        shippingAddress={valuesState.shippingAddress}
        invoiceId={props.invoiceId}
        refreshPaymentStatus={refreshPaymentStatus}
      />
    </div>
  );
}

const removeExistingQRCode = () => {
  const container = document.getElementById('qr_code');
  if (container != null && container.childNodes[0] != null) {
    container.removeChild(container.childNodes[0]);
  }
}

const addQRCode = (data) => {
  QRCode.toCanvas(data, {errorCorrectionLevel: 'H'}, function (err, canvas) {
    if (err) throw err

    const container = document.getElementById('qr_code');

    // If element does not exist, append child. If it does, replace child. This is to prevent more than 1 child
    if (container.childNodes[0] == null) {
      container.appendChild(canvas)
    } else {
      container.replaceChild(canvas, container.childNodes[0])
    }
  })
}

export default PayWithCurrencySubcontainer;
