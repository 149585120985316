import {useState} from "react";
import "./CellInput.scss";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


function CellInput({name, label, required}) {
  const [value, setValue] = useState('');

  let className = value === '' ? '' : 'filled'
  className += required === true ? ' required' : ''

  return (
    <div className="cell-container">
      <PhoneInput
        name={name}
        value={value}
        onChange={setValue}
        required={required}
      />
      <label className={className} htmlFor={value}>
        {label}
      </label>
    </div>
  );
}

export default CellInput;