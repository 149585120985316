import React, {useState} from "react";
import ChooseCurrencySubcontainer from "../subcontainers/ChooseCurrencySubcontainer/ChooseCurrencySubContainer";
import PayWithCurrencySubcontainer from "../subcontainers/PayWithCurrencySubcontainer/PayWithCurrencySubcontainer";

import "./AnonPayContainer.scss";
import CustomerDetailsSubcontainer from "../subcontainers/CustomerDetailsSubcontainer/CustomerDetailsSubcontainer";

const AnonPayContainer = props => {
  const [anonPayState, setAnonPayState] = useState({
    activeCurrency: null,
    customerDetailsSet: false
  });


  const setCurrency = (currency) => {
    setAnonPayState({activeCurrency: currency})
  }

  const goBack = () => {
    setAnonPayState({activeCurrency: null})
  }

  const getInvoiceId = () => {
    return props.searchParams.get("invoice-id")
  }

  const getCustomerDetailsRequested = () => {
    return props.searchParams.get("cd")
  }

  const subContainerToRender = () => {
    if (anonPayState.customerDetailsSet === false && getCustomerDetailsRequested() === "1") {
      return (
        <div className="AnonPayContainer">
          <CustomerDetailsSubcontainer
            invoiceId={getInvoiceId()}
            state={setAnonPayState}
          />
        </div>
      )
    }
    if (anonPayState.activeCurrency == null) {
      return (
        <div className="AnonPayContainer">
          <ChooseCurrencySubcontainer
            currencyCodeList={["BTC", "XMR", "ETH", "USDT", "DOGE", "DCR"]}
            setCurrency={setCurrency}/>
        </div>
      )
    } else {
      return (
        <div className="AnonPayContainer">
          <PayWithCurrencySubcontainer
            activeCurrency={anonPayState.activeCurrency}
            goBack={goBack}
            invoiceId={getInvoiceId()}
          />
        </div>
      )
    }
  }

  return (
    subContainerToRender()
  );
}

export default AnonPayContainer;
