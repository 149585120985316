import React from "react";

import "./PaymentHeader.scss";
import "./../../../App.scss"

const PaymentHeader = ({header, subhead, currencySubhead}) => {

  const generateSubHeader = () => {
    if (currencySubhead === undefined) {
      return (<h3>{subhead}</h3>);
    } else {
      return (
        <h3><span className="sub-head">{subhead} > </span>{currencySubhead}
        </h3>)
    }
  }

  return (
    <div className="payment-header">
      <div className="header-container">
        <h1>{header}</h1>
        {generateSubHeader()}
      </div>
      <img src={'cc.png'} alt="colorcrypto"/>
    </div>
  );
}

export default PaymentHeader;
