import {useState} from "react";
import "./Footer.scss"

function Footer({footer}) {

  return (
    <div className="footer">
      <h5>{footer}</h5>
    </div>
  );
}

export default Footer;