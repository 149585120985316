import React from "react";
import "./ChooseCurrencySubcontainer.css";
import CurrencyOptionGrid from "../../../components/CurrencyOptionGrid/CurrencyOptionGrid";

import PaymentHeader from "../../../components/PaymentHeader/PaymentHeader"
import Footer from "../../../components/Footer/Footer";

const ChooseCurrencySubcontainer = props => {
  return (
    <div className="ChooseCurrencySubcontainer">
      <PaymentHeader header="Crypto Payment" subhead="Select currency to pay"/>
      <CurrencyOptionGrid
        currencyCodeList={props.currencyCodeList}
        setCurrency={props.setCurrency}
      />
      <Footer footer="Powered by colorcrypto.io"/>
    </div>
  );
}

export default ChooseCurrencySubcontainer;
