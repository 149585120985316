import {useState} from "react";
import "./TextInput.scss";

function TextInput({type, name, label, pattern, required}) {
  const [value, setValue] = useState('');

  function handleChange(e) {
    setValue(e.target.value);
  }

  let className = value === '' ? '' : 'filled'
  className += required === true ? ' required' : ''

  return (
    <div className="input-container">
      <input type={type} name={name || type} value={value} onChange={handleChange} pattern={pattern} required={required}/>
      <label className={className} htmlFor={value}>
        {label}
      </label>
    </div>
  );
}

export default TextInput;